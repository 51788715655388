<template>
	<div style="background: #fff;
	padding-bottom: 20px; 
	box-sizing: border-box;
">
		<div class="typebox">
			<div @click="tourl(1)" :class="{ 'tab': true, 'currenttab': type == 1 }">出让公告</div>
			<div @click="tourl(2)" :class="{ 'tab': true, 'currenttab': type == 2 }">成交公告</div>
			<div @click="tourl(3)" :class="{ 'tab': true, 'currenttab': type == 3 }">最新公告</div>
		</div>

		<div class="indexgonggao">

			<div class="citybtn">
				<div @click="tocity(item)" :class="{ 'btn': true, 'checkedbtn': item.checked }" v-for="item in citylist"
					:key="item.city_id">{{
		item.city
}}</div>
			</div>

		</div>

		<div class="item" v-for="item in list" :key="item.id" @click="toDetail(item.id)">
			<div class="titlearea">
				<img v-if="item.is_new" style="display:inline-block;width:48px;margin-right:10px;position: relative;top:5px;"
					src="@/assets/images/new.png" alt="">
				{{ item.title }}
			</div>
			<div class="timearea">{{ item.created_at }}</div>
		</div>


		<div class="page">


			<el-pagination  @current-change="handleCurrentChange" :current-page="page"
				:page-size="size" background layout="prev, pager, next" :total="total">
			</el-pagination>
		</div>

	</div>
</template>

<script>

import { gonggaolist } from '@/api/website/notice'
export default {
	name: 'PcWebsiteGonggao',

	data() {
		return {
			type: null,
			citylist: [],
			list: [],
			currentcityid: null,
			page: 1,
			size: 20,
			total:null,
		};
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},

	watch: {
		$route: {
			handler(v) {
				const type = this.$route.params.type
				this.type = type
			
				let page = this.$route.query.page
				if(page == null){
					this.page = 1
				}else{
					this.page = parseInt(page)
				}
		
				this.initList()
			}
		},

	},

	mounted() {
		const type = this.$route.params.type
		this.type = type
		this.initList()
	},

	methods: {
		handleCurrentChange(e){
		
			const tourl = this.$route.path+'?page='+e
			this.$router.push(tourl)
		},
		toDetail(id) {
			const tourl = '/' + this.site.path + '/detail/gonggao/' + id
			// this.$router.push(tourl)
			let { href } = this.$router.resolve({ path:tourl })
			window.open(href, '_blank')
		},
		tocity(item) {
			// alert(item.city_id)
			const tourl = '/' + this.site.path + '/list/gonggao/' + this.type + '/' + item.city_id
			this.$router.push(tourl)
		},
		tourl(type) {
			const tourl = '/' + this.site.path + '/list/gonggao/' + type + '/' + this.$route.params.cityid
			this.$router.push(tourl)
		},
		initData() {

		},

		//初始化列表
		initList() {
			const params = {
				type: this.type,
				site_id: this.site.id,
				page: this.page,
				size: this.size
			}

			if (this.$route.params.cityid) {
				params.city_id = this.$route.params.cityid
			}

			gonggaolist(params).then(res => {
				this.citylist = res.data.city_list
				const currentcity = this.citylist.filter(item => {
					return item.checked === true
				})

				this.list = res.data.list
				this.total = res.data.total
			})
		}


	},
};
</script>

<style lang="scss" scoped>
.item {
	cursor: pointer;
	display: flex;
	height: 48px;
	align-items: center;
	line-height: 48px;
	justify-content: space-between;
	margin: 0 20px;
	border-bottom: solid 1px #F0F3F8;

	.titlearea {
		display: inline-block;
		width: 280px;
		height: 48px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 48px;
		text-align: left;
		overflow: hidden; //超出的文本隐藏
		text-overflow: ellipsis; //溢出用省略号显示
		white-space: nowrap; // 默认不换行；

	}

	.timearea {
		width: 84px;
		height: 17px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #A0A0A0;
		line-height: 17px;
	}
}


.indexgonggao {
	padding: 10px 15px;
	// background:red;
	width: 800px;
	margin-left: 8px;
	display: flex;

	.subject {
		width: 170px;
		height: 44px;
		background: #FFFFFF;
		border-radius: 2px;
		margin: 30px 20px;
	}

	.citybtn {
		display: flex;
		flex-wrap: wrap;
	}

	.btn {
		padding:0 10px;
		height: 28px;
		line-height: 28px;
		background: #F2F2F2;
		border-radius: 2px;
		margin-right: 12px;
		margin-top: 16px;
		cursor: pointer;
	}

	.checkedbtn {
		background: #00A3E4;
		color: #fff;
	}
}


.typebox {
	display: flex;
	margin-left: 8px;
	margin-top: 2px;
	border-bottom: solid 4px #F2F2F2;

	.tab {
		width: 140px;
		height: 50px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 50px;
		cursor: pointer;

	}

	.currenttab {
		background: #00A3E4;
		color: #FFFFFF;
	}


}

.page {
	margin-top: 30px;
	margin-bottom: 30px;
}
</style>